import axios from "axios";
import VueAxios from "vue-axios";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(VueAxios, axios);
Vue.use(Vuex);

import router from "../router/index";

export default new Vuex.Store({
  state: {
    menu_loaded:false,
    default_lang: null,
    default_dir: null,
    // def_path: "http://127.0.0.1:8000",
    def_path: "https://app.menyo.co",
    counter: 0,
    store_info: {
      permissions: [],
      store_name:""
    },
    static_trans:[],
    categories: [],
    color: "#808",
    cart: [],
    cart_alert:false,
    user_menu_customer:
      JSON.parse(localStorage.getItem("user_menu_customer")) || null,
    token: localStorage.getItem("token_menu_customer") || null,
    login_dialog: false,
  },
  getters: {
    cart(state) {
      return state.cart;
    },
    trans:(state) => (section, key) => {
      let keys = section ? state.static_trans.find((e) => e.section == section) : null;
      return keys ? keys.keys[key][state.default_lang] ? keys.keys[key][state.default_lang] : keys.keys[key].default_trans : null;
    },
    loggedin(state) {
      return state.token != null;
    },
    category: (state) => (category_id) => {
      let category = state.categories.find((e) => e.id == category_id);
      return {
        cat: category ? category : [],
        empty: !category ? true : false,
      };
    },
    colors(state) {
      return state.store_info.primaryColor;
    },
    permissions(state) {
      let per = [];
      state.store_info.permissions.forEach((e) => {
        per.push(e.name);
      });
      return per.toString();
    },
    store_name(state) {
      return state.store_info.store_name;
    }
  },
  mutations: {
    login(state, token) {
      state.token = token;
    },
    user_menu_customer(state, user) {
      state.user_menu_customer = user;
    },
    update_store(state, store) {
      state.store_info = store;
      state.default_lang = store.languages[0].locale;
      state.default_dir = store.languages[0].dir;
    },
    update_categoires(state, categories) {
      state.categories = categories;
    },
    update_static_trans(state, trans) {
      state.static_trans = trans;
    }
  },
  actions: {
    login(context, cred) {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("phone", cred.phone);
        formData.append("store_name", cred.store_name);

        var config = {
          method: "post",
          url: "customer_login",
          headers: {
            Accept: "application/json",
          },
          data: formData,
        };
        axios(config)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    get_menu(context, cred) {
      console.log(router.history.current.params.id)
      if (router.history.current.query.menu || router.history.current.params.menu) {


        let config = {
          method: "get",
          url: `menu/${router.history.current.query.menu || router.history.current.params.menu}`,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        };
        axios(config)
          .then((res) => {
            console.log(res);
            context.commit("update_store", res.data.data.user);
            context.commit("update_categoires", res.data.data.items);
            context.commit("update_static_trans", res.data.data.translations);
            console.log(res.data.data);
            context.state.menu_loaded = true;
          })
          .catch((e) => {
            // router.push({
            //   name: "notfound",
            // });
            console.log(e.response) 
          });
      } else {
        // router.push({
        //   name: "notfound",
        // });
      }
    },
    // public request this function handel any requset type like post & get Etc ...
    public__request(context, cred) {
      return new Promise((resolve, reject) => {
        let config = {
          method: cred.config.method,
          url: cred.config.url,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${context.state.token}`,
          },
          data: cred.data || [],
        };
        axios(config)
          .then((res) => {
            resolve(res);
            if (cred.get) {
              context.commit("public__request", {
                data: res.data,
                name: cred.get,
              });
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    get_cart(context) {
      let config = {
        method: "get",
        url: `cart/${router.history.current.query.menu || router.history.current.params.id}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${context.state.token}`,
        },
      };
      axios(config)
        .then((res) => {
          context.state.cart = Object.assign([], res.data.data);
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  },
  modules: {},
});
